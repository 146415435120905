/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { withRouter } from "react-router";


class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            phone:'',
            organization_id: '5f048db166e1c93c780e7e7c',
        };
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log('fields', this.state.fields);

    }

    Subscribe = () => {

        const formData = new FormData();
        formData.append('organization_id', this.state.organization_id);
        formData.append('name', this.state.fields.name);
        formData.append('email', this.state.fields.email);
        formData.append('mobile', this.state.phone);
        formData.append('status', '1');
        formData.append('created_by', 'Website');

        console.log(JSON.stringify(formData));

        axios.post(process.env.react_app_base_url + '/api/v1/frontend/contact_form', formData)
            .then(res => {
                console.log("res", res)
                const message = res.data.messages;

                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 5000,
                });

                this.props.history.push("/thank-you/")

                this.setState({
                    fields: {
                        "name": '',
                        "email": '',
                        "mobile": '',
                    },
                    phone:''

                })

            })
            .catch(error => {

                console.log("error.response.status", error)
                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            });

    }

    render() {
        return (
            <React.Fragment>
                <p><TextField name="name" size="small" value={this.state.fields.name} onChange={this.handleChange} id="outlined-basic" label="Name" variant="outlined" /></p>
                <p><TextField name="email" size="small" value={this.state.fields.email} onChange={this.handleChange} id="outlined-basic" label="Email Id" variant="outlined" /></p>
                <PhoneInput
                    country={'in'}
                    value={this.state.phone}
                    onChange={phone => this.setState({ phone })}
                />

                <p><Button onClick={this.Subscribe} variant="contained" color="primary"> Submit </Button></p>
            </React.Fragment>
        );
    }
}


export default withRouter(withSnackbar(ContactForm));