/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import CourseImage from '../../images/course_image.jpg';
import FreeResources from '../../components/website/freeResources';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import ReactModal from 'react-modal';
import InquireCourse from '../../components/website/inquiryCourse';
import { withSnackbar } from 'notistack';
import BuyThiCourse from './payment';
import Tooltip from '@material-ui/core/Tooltip';
import Blog from '../../components/website/blog';
import iconDownload from '../../images/iconDownload.png'
import iconEnquiry from '../../images/iconEnquiry.png'
import buyNowIcon from '../../images/buyNowIcon.png'
import { Loader } from '../../components/website/Loader';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

function courseFess(params, params2) {
    console.log("params", params?.length)
    console.log("params2", params2)
    if (params?.length === 0) { return 0 }
    const baseFees = params;
    let discount = null
    if (params2 == null) {
        discount = 0
    } else {
        discount = params2
    }

    console.log("baseFees", baseFees)
    console.log("discount", discount)
    const discountAmount = (parseInt(baseFees) / 100) * parseInt(discount)
    const total = parseInt(baseFees) - parseInt(discountAmount)
    console.log("total", total)
    return total
}

class CourseDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            tableData: null,
            centers: null,
            attachment: null,
            dataNew: 0,
            discountSelect: null,
            discount_table: [],
            user_id: localStorage.getItem('user_id') || null,
            fields: {
                batch_name: '',
                plan_name: '',
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.DownloadCurriculum = this.DownloadCurriculum.bind(this);
        this.handleChangeDiscount = this.handleChangeDiscount.bind(this);



    }

    handleOpenModal(data) {

        console.log("this.state.fields.plan" + this.state.fields.plan_name)
        console.log("this.state.fields.batch_name" + this.state.fields.batch_name)

        if (this.state.fields.batch_name?.length !== 0) {

            this.setState({ showModal: true });
        } else {

            const message = "Please select the Batch & Plan";
            this.props.enqueueSnackbar(message, {
                variant: 'error',
                autoHideDuration: 7000,
            });
        }

    }

    handleChangeDiscount(e) {
        const discountSelect = e.target.value;
        console.log('fields', discountSelect);
        this.setState({ discountSelect: discountSelect })
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    DownloadCurriculum() {
        console.log("Data Download Curriculum")

        if (this.state.user_id == null) {

            this.props.history.push({
                pathname: '/login/'
            });

        } else {
            window.open(process.env.react_app_base_url + "/" + this.state.tableData.attachment, '_blank');
        }
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log('fields', this.state.fields);

    }

    async componentDidMount() {


        console.log("this.props.match.params.id", this.props.match.params.id)
        console.log("this.props.match.params.title", this.props.match.params.title)
        const id = this.props.match.params.id

        this.getAllCMS(id);
        this.getAllBatch(id);
        // this.getAllDiscount(id)

        const fields = {
            batch_name: '',
            plan_name: '',
        }

        this.setState({ fields: fields })

    }

    componentWillReceiveProps(nextProps) {

        const fields = {
            batch_name: '',
            plan_name: '',
        }

        this.setState({ fields: fields })

        console.log("nextProps", nextProps.match.params.title)
        console.log("nextProps", nextProps.match.params.id)
        const id = nextProps.match.params.id
        this.getAllCMS(id);
        this.getAllBatch(id);

    }


    async getAllCMS(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources/' + id)
            .then(res => {
                console.log("Courses res", res)
                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getAllBatch(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/centerbatch/byCourseId/' + id+ "?status=0")
            .then(res => {
                console.log("Courses res", res)
                const dataNew = res.data.data;
                console.log("dataNew", dataNew.length)
                this.setState({ centers: res.data.data.reverse(), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }


    async getAllDiscount(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/discount/byProduct/' + id)
            .then(res => {
                console.log("Courses res", res)
                this.setState({ discount_table: res.data.data, loading: false, discountSelect: res.data.data[0].discount })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }




    render() {
        return (
            <React.Fragment>
                {this.state.tableData == null ?  <Loader></Loader> :
                    <React.Fragment>
                        <SubHeader title={this.state.tableData.name}></SubHeader>
                        <div className="courseDetails">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 col-sm-12 text-left elementGab">
                                        <h3>{this.state.tableData.name}</h3>
                                        <div className="desc">
                                            <div>
                                                <div dangerouslySetInnerHTML={{ __html: this.state.tableData.short_description }}></div>
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <div className='float-left mr-4'>
                                                {this.state.tableData.attachment == null ? '' :
                                                    <Tooltip title="Sign up to download">
                                                        <Button variant="contained" onClick={() => this.DownloadCurriculum('DownloadCurriculum')} className="bigBtn" color="secondary">
                                                            <img src={iconDownload} alt="" title='' />Download Curriculum </Button>
                                                    </Tooltip>
                                                }
                                            </div>
                                            <div className='float-left '>
                                                <Button variant="contained" onClick={() => this.handleOpenModal('James')} className="bigBtn" color="secondary">
                                                    <img src={iconEnquiry} alt="" title='' /> Enquire Now </Button>
                                            </div>
                                            <div className='clearfix'></div>
                                        </div>
                                        <ReactModal
                                            isOpen={this.state.showModal}
                                            contentLabel="Minimal Modal Example"
                                            className="Modal"
                                            overlayClassName="Overlay"
                                        >
                                            <div className="popup">
                                                <Button className="closeBtn" color="primary" variant="outlined" onClick={this.handleCloseModal}>
                                                    <span className="ti-close iconSpan"></span> Close
                                                </Button>
                                                <InquireCourse
                                                    course_name={this.state.tableData.name}
                                                    course_id={this.state.tableData._id}
                                                    plan_name={this.state.fields.plan_name || 'Video and Notes'}
                                                    batch_name={this.state.fields.batch_name}
                                                >
                                                </InquireCourse>
                                            </div>
                                        </ReactModal>



                                        <div className="description">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.tableData.description }}></div>
                                        </div>



                                        <div className="compare">
                                            <h5><strong>Course Batch</strong></h5>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th> Name </th>
                                                        <th> Description </th>
                                                        <th> Duration </th>
                                                        <th> Start Date </th>
                                                        <th> End Date  </th>
                                                    </tr>
                                                </thead>

                                                {this.state.centers !== null ?
                                                    <React.Fragment>
                                                        {this.state.centers.map((item) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td> <div dangerouslySetInnerHTML={{ __html: item.name }}></div>  </td>
                                                                        <td> <div dangerouslySetInnerHTML={{ __html: item.description }}></div> </td>
                                                                        <td> {item.batch_days} </td>
                                                                        <td> {item.start_date} </td>
                                                                        <td> {item.end_date} </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <tr>
                                                            <td colSpan="5"> No Batch Available </td>
                                                        </tr>
                                                    </React.Fragment>
                                                }


                                                {this.state.centers !== null ?
                                                    <React.Fragment>
                                                        {this.state.centers.length == 0 ? <tr>
                                                            <td colSpan="5"> No Batch Available </td>
                                                        </tr> : ''}
                                                    </React.Fragment>
                                                    : ''}

                                            </table>
                                        </div>

                                        {this.state.tableData.fees !== "null" && this.state.tableData.fees.length !== 0 ?
                                            <React.Fragment>
                                                <div className="compare">
                                                    <h5><strong>Plan Comparison</strong></h5>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Video & Notes</th>
                                                                <th>Video</th>
                                                            </tr>
                                                        </thead>
                                                        <tr>
                                                            <td>Video</td>
                                                            <td><span className="ti-check-box"></span></td>
                                                            <td><span className="ti-check-box"></span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Notes</td>
                                                            <td><span className="ti-check-box"></span></td>
                                                            <td> - </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </React.Fragment>
                                            : null}

                                        {this.state.discount_table !== "null" && this.state.discount_table.length !== 0 ?
                                            <React.Fragment>
                                                <div className="compare">
                                                    <h5><strong>Discount</strong></h5>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Description</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>
                                                                <th>Discount</th>
                                                            </tr>
                                                        </thead>

                                                        {this.state.discount_table.length !== 0 ?
                                                            <React.Fragment>
                                                                {this.state.discount_table.map((item) => {
                                                                    return (
                                                                        <React.Fragment>
                                                                            <tr>
                                                                                <td> {item.title} </td>
                                                                                <td> {item.description} </td>
                                                                                <td> {item.start_date} </td>
                                                                                <td> {item.end_date} </td>
                                                                                <td> {item.discount} </td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                                }
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td colSpan="5"> No Discount Available </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        }

                                                    </table>
                                                </div>
                                            </React.Fragment>
                                            : null}




                                    </div>

                                    <div className="col-md-4 rightPanel col-sm-12 text-left elementGab">
                                        <div className='inner'>
                                            {/* <p className='paraDuration'>Duration of the course: 9 hours </p> */}

                                            {this.state.discountSelect == null ? '' :
                                                <React.Fragment>
                                                    <p class="base_fees"> Base fees: INR &nbsp; {this.state.tableData.fees == "null" ? '' : this.state.tableData.fees.length == 0 ? '' : this.state.tableData.fees + '-'}
                                                        {this.state.tableData.fees1 == "null" ? '' : this.state.tableData.fees1}  </p></React.Fragment>
                                            }

                                            {this.state.discountSelect == null ? '' : <p><strong>Get an early bird discount of {this.state.discountSelect} %</strong></p>}

                                            <p className="fees"><strong>Fees : </strong>
                                           
                                            {(this.state.tableData.fees1 === 'null' || this.state.tableData.fees1?.length === 0) && (this.state.tableData.fees === 'null' ||  this.state.tableData?.fees.length === 0) ?  ('Course Not Available') : null }
                                                {this.state.tableData.fees == "null" ? '' : this.state.tableData.fees.length === 0 ? '' : 'INR ' + courseFess(this.state.tableData.fees, this.state.discountSelect) + '-'}
                                                {this.state.tableData.fees1 == "null" ? '' : this.state.tableData.fees1.length === 0 ? '' : 'INR ' + courseFess(this.state.tableData.fees1, this.state.discountSelect)}
                                            </p>  
                                            <p style={{marginTop:'-20px', fontSize:'14px'}}>Inclusive of applicable taxes</p>                                              
                                            {this.state.discount_table !== null && this.state.discount_table.length !== 0 ?
                                                <React.Fragment>
                                                    <label>Select Discount Code</label>
                                                    <p>
                                                        <FormControl variant="outlined" size="small" className={''}>
                                                            <Select
                                                                variant="outlined"
                                                                size="medium"
                                                                name="batch_name"
                                                                labelId="batch_name"
                                                                id="batch_name"
                                                                value={this.state.discountSelect}
                                                                onChange={this.handleChangeDiscount}
                                                            >
                                                                {this.state.discount_table.map((item) => {
                                                                    return (
                                                                        <MenuItem value={item.discount}> {item.title} </MenuItem>
                                                                    );
                                                                })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </p>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>

                                                </React.Fragment>
                                            }



                                            <div className="mb-2">
                                                {this.state.centers !== null && this.state.centers.length !== 0 ?
                                                    <React.Fragment>
                                                        <label>Select Batch</label>
                                                        <FormControl variant="outlined" size="small" className={''}>
                                                            <Select
                                                                variant="outlined"
                                                                size="medium"
                                                                name="batch_name"
                                                                labelId="batch_name"
                                                                id="batch_name"
                                                                value={this.state.fields.batch_name}
                                                                onChange={this.handleChange}
                                                            >
                                                                {this.state.centers.map((item) => {
                                                                    return (
                                                                        <MenuItem disabled={item.status === '1'} value={item._id}> {item.name} </MenuItem>
                                                                    );
                                                                })
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <label>Select Batch</label>
                                                        <p>No Batch Available</p>
                                                    </React.Fragment>
                                                }
                                            </div>


                                            {this.state.tableData.fees !== "null" && this.state.tableData.fees.length !== 0 ?
                                                <div className="mb-2">
                                                    <label>Select Plan</label>
                                                    <FormControl variant="outlined" size="small" className={''}>
                                                        <Select
                                                            variant="outlined"
                                                            size="medium"
                                                            name="plan_name"
                                                            labelId="plan_name"
                                                            id="plan_name"
                                                            value={this.state.fields.plan_name}
                                                            onChange={this.handleChange}
                                                        >


                                                            {this.state.tableData.fees !== "null" && this.state.tableData.fees.length !== 0 ? <MenuItem value='Only Video'>Only Video</MenuItem> : null}
                                                            {this.state.tableData.fees1 !== "null" && this.state.tableData.fees1.length !== 0 ? <MenuItem value='Video and Notes'>Video and Notes</MenuItem> : null}


                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                : null}

                                            <div className="mb-2" id="btn-group">
                                                <div className="">

                                                    <BuyThiCourse
                                                        course_name={this.state.tableData.name}
                                                        course_id={this.state.tableData._id}
                                                        plan_name={this.state.fields.plan_name || 'Video and Notes'}
                                                        batch_name={this.state.fields.batch_name}
                                                        batch_id={this.state.fields.batch_name}
                                                        discount_percentage={this.state.discountSelect}
                                                        icon={buyNowIcon}
                                                    ></BuyThiCourse>
                                                </div>
                                            </div>

                                            <div className='courseBottom'>
                                                <div dangerouslySetInnerHTML={{ __html: this.state.tableData?.right_section }}></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>

        );
    }
}


export default withSnackbar(CourseDetails);