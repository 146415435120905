/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import { Button } from "@material-ui/core";
import TopMenuItem from './topMenuItem';

export default class TopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerMenu: false,
            drodpdownState: false,
            mobileOnly: false,

        };
    }

    handleClose() {
        this.setState({ hamburgerMenu: false })

    }
    handleOpen() {
        this.setState({ hamburgerMenu: true })
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        const windwoWidth = window.innerWidth <= 990;

        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
        this.resize();
    }

    resize() {
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    render() {
        return (
            <React.Fragment>


                <div className="hangerMenu">

                    {this.state.hamburgerMenu === false ?
                        <div className="hangerMenuClose">

                            <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleOpen() }} >
                                <span></span>
                                <span></span>
                                <span></span>
                            </Button>
                        </div>
                        :
                        <div className="hangerMenuOpen">
                            <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleClose() }} >
                                <span></span>
                                <span></span>
                                <span></span>
                            </Button>
                        </div>

                    }

                </div>


                {this.state.hamburgerMenu === true ? ''
                    : <div className="top-menu" onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''}>
                        <TopMenuItem></TopMenuItem>
                    </div>}


            </React.Fragment>
        );
    }
}
