/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import SocialIcons from './social';
import Newsletter from './newsletter';
import TopMenuItem from './topMenuItem';
import CourseMenuItem from './TopCourseMenuItem';
import CourseCFA from './CourseCFA';
import CertificateCourse from './CertificateCourse';
import { Button } from "@material-ui/core";
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import FooterLogo from '../../images/FooterLogo.jpg'

export default class footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfa_menu: null,
            other_menu: null,
        };
    }

    async componentDidMount() {

        this.getAllCMS();

    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0')
            .then(res => {
                console.log("Courses res", res)

                const cfa_menu = res.data.data.filter(item => item.parent_course === "CFA");
                console.log("cfa_menu", cfa_menu)

                const other_menu = res.data.data.filter(item => item.parent_course !== "CFA");
                console.log("other_menu", other_menu)


                this.setState({ cfa_menu: cfa_menu, other_menu: other_menu, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)


            });

    }

    


    render() {
        const currentYear = new Date().getFullYear();

        return (
            <React.Fragment>
                
                <NavLink to="#top" className={'backToTop'} >Top</NavLink>
                <div className='FooterMenu'>
                    <ul className='text-center'>
                        <li>
                            <NavLink to="/courses" > Certificate Courses</NavLink>
                        </li>
                        <li>
                            <NavLink to="/courses" > FRM</NavLink>
                        </li>
                        <li>
                            <NavLink to="/career/career-counselling-for-BFSI/6082dfa16152290d084f7492" > Career Counselling - BFSI</NavLink>
                        </li>
                        <li>
                            <NavLink to="/free-resources" > Free Resources</NavLink>
                        </li>
                        <li>
                            <NavLink to="/blog" > Blog</NavLink>
                        </li>
                        <li>
                            <NavLink to="/testimonials" > Testimonials</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about-us" > About Us</NavLink>
                        </li>
                        <li>
                            <NavLink to="/cms/privacy-policy/608270136152290d084f748d" > Privacy Policy</NavLink>
                        </li>
                        
                    </ul>
                </div>
                <div className='socialMenu'>
                    Have a question? Connect with us on Social Media:
                    <ul className="social_icons">
                        <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/finquestinstitute/" fontIcon="ti-linkedin" title="">
                            <Button aria-controls="simple-menu" aria-haspopup="true">
                                <span className={'fontIcon  ti-linkedin'}></span>
                                <span className="text">
                                </span>
                            </Button> </a> </li>
                            <li><a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCprKtU-bGEgQzHl9HHDwSUQ" fontIcon="ti-youtube" title="">

                            <Button aria-controls="simple-menu" aria-haspopup="true">
                                <span className={'fontIcon  ti-youtube'}></span>
                                <span className="text">
                                </span>
                            </Button>
                        </a> </li>
                        <li><a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/FinQuestInstitute/" fontIcon="ti-facebook" title="">

                            <Button aria-controls="simple-menu" aria-haspopup="true">
                                <span className={'fontIcon  ti-facebook'}></span>
                                <span className="text">
                                </span>
                            </Button>
                        </a> </li>
                        <li><a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/finquest_institute/" fontIcon="ti-instagram" title="">

                            <Button aria-controls="simple-menu" aria-haspopup="true">
                                <span className={'fontIcon ti-instagram'}></span>
                                <span className="text">
                                </span>
                            </Button>
                        </a> </li>
                    </ul>
                </div>
                <div className="footer">
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-6'>
                                <img src={FooterLogo} alt="" title='' />
                            </div>
                            <div className='col-md-6'>
                                <p className='copyRight'>Copyright {currentYear} FinQuest Institute. All Rights Reserved / Privacy Policy </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
