/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import logo from '../../images/Finquest_logo.png';
import LinkItem from '../LinkItem';
import { NavLink } from 'react-router-dom';
import TopMenu from './topMenu';
import Button from "@material-ui/core/Button";
import axios from 'axios';
import { withSnackbar } from 'notistack';
import { withRouter } from "react-router";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import TopCourseMenuItem from './TopCourseMenuItem';
import AboutMenu from './AboutMenu';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLogged: localStorage.getItem("user_user_type") || null,
            user_token: localStorage.getItem("user_token") || null,
            user_name: localStorage.getItem("user_name") || null,
            hamburgerMenu: false,
            drodpdownState: false,
            mobileOnly: false,
        };
    }


    componentDidMount() {
        console.log("this.state.user_user_type", this.state.user_user_type)
        console.log("this.state.user_token", this.state.user_token)
        console.log("this.state.user_name", this.state.user_name)
        window.addEventListener("resize", this.resize.bind(this));
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
        this.resize();
    }

    handleClose() {
        this.setState({ hamburgerMenu: false })

    }
    handleOpen() {
        this.setState({ hamburgerMenu: true })
    }

    handleOpen2() {
        console.log("Adasda")
        this.setState({ hamburgerMenu: true })
    }

    resize() {
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    logout = () => {
        // logout

        const formData = {
            'token': this.state.user_token
        }

        axios.post(process.env.react_app_base_url + '/api/v1/user/logout', formData, {
            headers: {
                "auth-token": `${this.state.user_token}`,
            }
        })
            .then(res => {
                console.log("res", res)
                const message = res.data.messages;

                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 7000,
                });

                localStorage.removeItem("user_id");
                localStorage.removeItem("user_user_type");
                localStorage.removeItem("user_mobile");
                localStorage.removeItem("user_name");
                localStorage.removeItem("user_email");
                localStorage.removeItem("user_token");

                this.props.history.push("/login/")
                this.setState({ userLogged: null, user_token: null })

            })
            .catch(error => {

                console.log("error.response.status", error)
                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });

            });
    }

    render() {
        return (
            <React.Fragment>
                <div className='header'>
                    <div className='wrapper'>
                        <div className='topRow'>
                            <div className=''>
                                <span>
                                    <a href='mailto:info@finquestinstitute.com' title='info@finquestinstitute.com'>
                                        info@finquestinstitute.com
                                    </a>
                                </span>
                                <span>
                                    +91-8591017994
                                </span>
                            </div>
                        </div>
                        <div className='logoWrapper'>
                            <div className='row'>
                                <div className="col-md-4 col-sm-12 text-left flex-row">
                                    <NavLink to="/" className="site-logo"> <img alt="Logo" src={logo} /></NavLink>
                                    {this.state.hamburgerMenu === false ?
                                        <div className="hangerMenuClose">
                                            <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleOpen() }} >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </Button>
                                        </div>
                                        :
                                        <div className="hangerMenuOpen">
                                            <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleClose() }} >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </Button>
                                        </div>
                                    }

                                </div>
                                <div className="col-md-8 col-sm-12 text-right topMenu">
                                    {this.state.userLogged === null ?
                                        <div className="login_box">
                                            <ul>
                                                <li>
                                                    <NavLink to="/login" > LOGIN</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/signup" > SIGNUP</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className="user_name">
                                                <AccountBoxIcon></AccountBoxIcon> Welcome <strong>{this.state.user_name}</strong>
                                            </div>
                                            <div className="login_box">
                                                <ul>
                                                    <li><LinkItem to="/students/dashboard" fontIcon={"ti-dashboard"} title="Dashboard"></LinkItem> </li>
                                                    <li>
                                                        <Button className='logoutBtn' onClick={this.logout} aria-controls="simple-menu" aria-haspopup="true">
                                                            <span className="fontIcon ti-logout"><ExitToAppIcon></ExitToAppIcon></span>
                                                            <span>Logout</span></Button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </React.Fragment>
                                    }


                                </div>
                            </div>
                        </div>
                        {this.state.hamburgerMenu === true ? ''
                            :
                            <>
                                <div className='overLayer' onClick={() => { this.handleClose() }} ></div>
                                <div className="top-menu">
                                    {this.state.hamburgerMenu === false ?
                                        <div className="hangerMenuClose">
                                            <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleOpen() }} >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </Button>
                                        </div>
                                        :
                                        <div className="hangerMenuOpen">
                                            <Button variant="outlined" size="large" className="display-block MegaMenu" color="secondary" onClick={() => { this.handleClose() }} >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </Button>
                                        </div>
                                    }
                                    <div className='mainMenu'>
                                        <ul className='text-center'>
                                            {this.state.hamburgerMenu === false &&
                                                <div className='hangerMenuOuter'>
                                                    <div class="close-container" onClick={() => { this.handleOpen() }}>
                                                        <div class="leftright"></div>
                                                        <div class="rightleft"></div>
                                                    </div>
                                                </div>
                                            }
                                            <TopCourseMenuItem handleOpen2={() => this.handleOpen2()} ></TopCourseMenuItem>                    
                                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/career/career-counselling-for-BFSI/6082dfa16152290d084f7492" > Career Counselling - BFSI</NavLink> </li>
                                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/free-resources" > Free Resources</NavLink> </li>
                                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/blog" > Blog</NavLink> </li>
                                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/testimonials" > Testimonials</NavLink> </li>
                                            <AboutMenu handleOpen2={() => this.handleOpen2()}> </AboutMenu>

                                        </ul>
                                    </div>
                                </div>
                            </>}

                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default withRouter(withSnackbar(Header));