/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import CourseItem from './courseItem';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DefaultImage from '../../images/default2.jpg';
import LinkItem from '../LinkItem';

export default class CoursesComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null
        };
    }

    async componentDidMount() {

        this.getAllCMS();

    }

    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0')
            .then(res => {
                console.log("Courses res", res)
                this.setState({ tableData: res.data.data.reverse(), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="coursesComp">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="">Our Courses</h2>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.tableData === null ? '' : this.state.tableData.map(item => {
                                return (
                                    <React.Fragment>
                                        <div className="col-md-4 mb-3">
                                            <div className='inner'>
                                                <div className="image" style={{ background: `url(${process.env.react_app_base_url + "/" + item.image})` }}>
                                                </div>
                                                <h3>
                                                    <LinkItem title={item.name} to={`/view-course-details/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"}>
                                                    </LinkItem></h3>
                                            </div>
                                            </div>

                                    </React.Fragment>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}