/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { withSnackbar } from "notistack";
import BlogItem from "../../components/website/elementItem";
import StudentHeader from "../../components/website/studentHeader";
import LinkItem from "../../components/LinkItem";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SubHeader from "../../components/website/subHeader";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import logo from '../../images/logoTransperent.png';
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import DefaultImage from "../../images/default2.jpg";
import { Loader } from "../../components/website/Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

class ViewStudentVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      tabvalue: 0,
      files: [],
      fields: {},
      cms_description: null,
      cms_type: [],
      cms_category: [],
      type: null,
      cms_details: null,
      api_reps: false,
      cms_description: null,
      action: null,
      tableData: null,
      tableModuleData: null,
      user_token: localStorage.getItem("user_token"),
      organization_id: localStorage.getItem("organization_id"),
      admin_name: localStorage.getItem("admin_name"),
      currentCourseBatchId:
        localStorage.getItem("currentCourseBatchId") || null,
      module_title: null,
      module_id: null,
      cource_name: null,
      cource_id: null,
    };
  }

  async componentDidMount() {
    // alert(JSON.stringify(this.props.history.location.state))

    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        event.preventDefault();
      }
    };

    // Disable text selection
    const handleSelectStart = (event) => {
      event.preventDefault();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('selectstart', handleSelectStart);

    const title = this.props.match.params.title;
    const id = this.props.match.params.id;
    const module_title = this.props.match.params.module_title;
    const module_id = this.props.match.params.module_id;
    console.log(
      "id, title, module_title, module_id",
      id,
      title,
      module_title,
      module_id
    );
    if (this.state.currentCourseBatchId === null) {
      alert("Please select the course again");
    }
    this.getAllCMS(module_id);
    this.getVideoDetails(id);
  }

  componentWillReceiveProps(nextProps) {
    // alert(JSON.stringify(this.props.history.location.state))
    console.log("nextProps", nextProps.match.params.id);
    const title = nextProps.match.params.title;
    const id = nextProps.match.params.id;
    const module_title = nextProps.match.params.module_title;
    const module_id = nextProps.match.params.module_id;
    console.log(
      "id, title, module_title, module_id",
      id,
      title,
      module_title,
      module_id
    );
    this.getAllCMS(module_id);
    this.getVideoDetails(id);
  }

  async getAllCMS(module_id) {
    this.setState({ loading: true });
    axios
      .get(
        process.env.react_app_base_url +
        "/api/v1/videos/videosbymodule/" +
        module_id +
        "?status=0&batch_id=" +
        this.state.currentCourseBatchId,
        {
          headers: {
            "auth-token": `${this.state.user_token}`,
          },
        }
      )
      .then((res) => {
        console.log("videosbymodule", res);
        this.setState({
          tableData: res.data.data,
          loading: false,
          module_name: res.data.data[0].module_name,
          module_id: res.data.data[0].module_id,
          cource_name: res.data.data[0].cource_name,
          cource_id: res.data.data[0].cource_id,
        });
      })
      .catch((error) => {
        console.log("error.response.status", error);
      });
  }

  async getVideoDetails(id) {
    this.setState({ loading: true });
    axios
      .get(process.env.react_app_base_url + "/api/v1/videos/" + id, {
        headers: {
          "auth-token": `${this.state.user_token}`,
        },
      })
      .then((res) => {
        console.log("Resources moduleByCourse", res);
        this.setState({ tableModuleData: res.data.data, loading: false });
      })
      .catch((error) => {
        console.log("error.response.status", error);
      });
  }

  handleChangeIndex = (event, tabvalue) => {
    this.setState({ tabvalue });
  };

  handleChange = (event, tabvalue) => {
    this.setState({ tabvalue });
  };

  render() {
    const { value, tabvalue } = this.state;
    return (
      <React.Fragment>
        <StudentHeader></StudentHeader>
        {this.state.tableData === null ? (
          ""
        ) : (
          <div className="container">
            <p className="backtoBtn">
              <LinkItem
                to={
                  `/students/view-chapter-by-module/` +
                  this.state.module_name
                    .replace(/\s+/g, "-")
                    .toLowerCase()
                    .replace(",", "")
                    .replace("(", "")
                    .replace(")", "")
                    .replace("/", "-")
                    .replace("!", "")
                    .replace("@", "-")
                    .replace("#", "-")
                    .replace("$", "-")
                    .replace("%", "-")
                    .replace("^", "-")
                    .replace("&", "-")
                    .replace("*", "-")
                    .replace("_", "-")
                    .replace(".", "-")
                    .replace(";", "-")
                    .replace("'", "-")
                    .replace("'", "-")
                    .replace(":", "-")
                    .replace("{", "")
                    .replace("}", "-")
                    .replace("[", "-")
                    .replace("]", "-")
                    .replace("=", "-")
                    .replace("+", "-")
                    .replace("|", "-")
                    .replace(/"/g, "'")
                    .replace(/\\\//g, "") +
                  "/" +
                  this.state.module_id +
                  "/" +
                  this.state.cource_name
                    .replace(/\s+/g, "-")
                    .toLowerCase()
                    .replace(",", "")
                    .replace("(", "")
                    .replace(")", "")
                    .replace("/", "-")
                    .replace("!", "")
                    .replace("@", "-")
                    .replace("#", "-")
                    .replace("$", "-")
                    .replace("%", "-")
                    .replace("^", "-")
                    .replace("&", "-")
                    .replace("*", "-")
                    .replace("_", "-")
                    .replace(".", "-")
                    .replace(";", "-")
                    .replace("'", "-")
                    .replace("'", "-")
                    .replace(":", "-")
                    .replace("{", "")
                    .replace("}", "-")
                    .replace("[", "-")
                    .replace("]", "-")
                    .replace("=", "-")
                    .replace("+", "-")
                    .replace("|", "-")
                    .replace(/"/g, "'")
                    .replace(/\\\//g, "") +
                  "/" +
                  this.state.cource_id
                }
                title={"Back to Chapter"}
              ></LinkItem>
            </p>
          </div>
        )}
        <div className="student-dashboard">
          <div className="container">
            {this.state.tableData == null ? (
              "Video yet not uploaded for this module."
            ) : (
              <div className="row text-left">
                {/* {JSON.stringify(this.state.tableData[0])} */}

                <div className="col-md-9" id="mainVideo">
                  {this.state.tableModuleData == null ? (
                    <React.Fragment>
                      <div className="info error">Please select the video from right sidebar panel.</div>
                    </React.Fragment>
                  ) : null}

                  {this.state.tableModuleData === null ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <div className="admin-list">
                        <div className="blogItem">
                          {/* <div class="image">
                            {this.state.tableModuleData.image == null ? (
                              <img src={DefaultImage} alt="" />
                            ) : (
                              <img
                                src={
                                  process.env.react_app_base_url +
                                  "/" +
                                  this.state.tableModuleData.image
                                }
                                alt=""
                              />
                            )}
                          </div> */}
                          <div className="textWrapper2">
                            <h5>{this.state.tableModuleData.name}</h5>
                            <p>
                              {this.state.tableModuleData.chapter_name +
                                " / " +
                                this.state.tableModuleData.module_name +
                                " / " +
                                this.state.tableModuleData.cource_name}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* {JSON.stringify(this.state.tableModuleData)} */}

                      {this.state.tableModuleData?.video && this.state.tableModuleData?.video.length &&
                        this.state.tableModuleData?.video.includes("vimeo") ? <iframe
                          title="Contact map"
                          allow="autoplay; fullscreen" allowfullscreen
                          src={this.state.tableModuleData.video}
                        ></iframe> : <div className="info mb-3">Video yet not uploaded for this module.</div>}


                      <AppBar position="static" color="default">
                        <Tabs
                          value={tabvalue}
                          onChange={this.handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                        >
                          <Tab label="Description" {...a11yProps(0)} />
                          <Tab label="View Note" {...a11yProps(1)} />
                        </Tabs>
                      </AppBar>

                      <SwipeableViews
                        index={tabvalue}
                        onChangeIndex={this.handleChangeIndex}
                      >
                        <TabPanel value={tabvalue} index={0}>
                          <div class="copy description" id="note-logo">
                            <div className="waterMark" style={{ background: `url(${logo})`, backgroundSize: "contain" }}>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.tableModuleData.description,
                              }}
                            ></div>
                          </div>
                        </TabPanel>
                        <TabPanel value={tabvalue} index={1}>
                          <div class="copy description" id="note-logo">
                            <div className="waterMark" style={{ background: `url(${logo})`, backgroundSize: "contain" }}>
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.tableModuleData.note,
                              }}
                            ></div>
                          </div>
                        </TabPanel>
                      </SwipeableViews>
                    </React.Fragment>
                  )}
                </div>

                <div className="col-md-3" id="sidebarVideo">
                  <Paper square>
                    <TabPanel value={this.state.value} index={0}>
                      {this.state.tableData === null
                        ? <Loader></Loader>
                        : this.state.tableData.map((item, index) => {
                          return (
                            <React.Fragment>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={""}>
                                    {" "}
                                    {index + 1}. {item.name}{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  

                                  <div>
                                    <LinkItem
                                      className="view-details"
                                      variant="primary"
                                      to={
                                        `/students/view-video-by-chapter/${item.name
                                          .replace(/\s+/g, "-")
                                          .toLowerCase()
                                          .replace(",", "")
                                          .replace("(", "")
                                          .replace(")", "")
                                          .replace("/", "-")
                                          .replace("!", "")
                                          .replace("@", "-")
                                          .replace("#", "-")
                                          .replace("$", "-")
                                          .replace("%", "-")
                                          .replace("^", "-")
                                          .replace("&", "-")
                                          .replace("*", "-")
                                          .replace("_", "-")
                                          .replace(".", "-")
                                          .replace(";", "-")
                                          .replace("'", "-")
                                          .replace("'", "-")
                                          .replace(":", "-")
                                          .replace("{", "")
                                          .replace("}", "-")
                                          .replace("[", "-")
                                          .replace("]", "-")
                                          .replace("=", "-")
                                          .replace("+", "-")
                                          .replace("|", "-")
                                          .replace(/"/g, "'")
                                          .replace(
                                            /\\\//g,
                                            ""
                                          )}/${item._id.replace(
                                            /\s+/g,
                                            "-"
                                          )}/${item.module_name
                                            .replace(/\s+/g, "-")
                                            .toLowerCase()
                                            .replace(",", "")
                                            .replace("(", "")
                                            .replace(")", "")
                                            .replace("/", "-")
                                            .replace("!", "")
                                            .replace("@", "-")
                                            .replace("#", "-")
                                            .replace("$", "-")
                                            .replace("%", "-")
                                            .replace("^", "-")
                                            .replace("&", "-")
                                            .replace("*", "-")
                                            .replace("_", "-")
                                            .replace(".", "-")
                                            .replace(";", "-")
                                            .replace("'", "-")
                                            .replace("'", "-")
                                            .replace(":", "-")
                                            .replace("{", "")
                                            .replace("}", "-")
                                            .replace("[", "-")
                                            .replace("]", "-")
                                            .replace("=", "-")
                                            .replace("+", "-")
                                            .replace("|", "-")
                                            .replace(/"/g, "'")
                                            .replace(
                                              /\\\//g,
                                              ""
                                            )}/${item.module_id.replace(
                                              /\s+/g,
                                              "-"
                                            )}` + "/"
                                      }
                                      title={"View Details"}
                                    ></LinkItem>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </React.Fragment>
                          );
                        })}
                    </TabPanel>
                  </Paper>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(ViewStudentVideo);
