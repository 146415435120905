/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import axios from 'axios';
import Banner from '../../images/Banner.png'
// import Style from './about-home.module.scss'
import PropsType from 'prop-types'
import USP from './usp';
import { Loader } from './Loader';
export default class AboutHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: null
        };
    }

    async componentDidMount() {
        this.getAllPages();
    }

    getAllPages() {
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages')
            .then(res => {
                console.log("getAllPages res", res.data.data)
                const meet_trainer = res.data.data.filter(item => item._id == "5f7746278e396339ca9b03d2");
                console.log("meet_trainer", meet_trainer[0])
                this.setState({ pages: meet_trainer[0] })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className=''>
                    <img src={Banner} alt="" title='' />
                </div>
                <div className="AboutHome">
                    <div className="container">
                        <div className='row'>
                            <div className='col-md-7 col-sm-12'>
                                {this.state.pages !== null ?
                                    <React.Fragment>
                                        <h2>{this.state.pages.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.pages.description }}></div>
                                    </React.Fragment>
                                    : <Loader></Loader>}
                            </div>
                            <div className='col-md-5 col-sm-12'>
                            <USP></USP>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
