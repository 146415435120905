import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

  

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		console.log("componentDidUpdate scroltotop")
		if (this.props.location !== prevProps.location) {
			console.log("componentDidUpdate scroltotop inside")

			ReactGA.initialize('UA-179087616-1');
			ReactGA.pageview(window.location.pathname + window.location.search);
			console.log("window.location.pathname", window.location.pathname)
			console.log("window.location.search", window.location.search)
			
			window.scrollTo(0, 0);
			
		}
	}

	componentWillReceiveProps(nextProps) {
		console.log("componentWillReceiveProps scroltotop")
		console.log("nextProps nextProps", nextProps.location)
		console.log("this.props.location", this.props.location)

		if (nextProps.location !== this.props.location) {
		  this.setState({ prevPath: this.props.location })
		  console.log("componentWillReceiveProps scroltotop inside")
		}
	  }
	

// <!-- Facebook Pixel Code -->
// <script>
// !function(f,b,e,v,n,t,s)
// {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
// n.callMethod.apply(n,arguments):n.queue.push(arguments)};
// if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
// n.queue=[];t=b.createElement(e);t.async=!0;
// t.src=v;s=b.getElementsByTagName(e)[0];
// s.parentNode.insertBefore(t,s)}(window, document,'script',
// 'https://connect.facebook.net/en_US/fbevents.js');
// fbq('init', '451964655676416');
// fbq('track', 'PageView');
// </script>
// <noscript><img height="1" width="1" style="display:none"
// src="https://www.facebook.com/tr?id=451964655676416&ev=PageView&noscript=1"
// /></noscript>
// <!-- End Facebook Pixel Code -->


	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop)
