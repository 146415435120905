import React from 'react';
import { deepOrange } from "@material-ui/core/colors";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import LayoutRoute from './container/LayoutRoute';
import NonLayoutRoute from './container/NonLayoutRoute';
import LayoutStudentRoute from './container/LayoutStudentsRoute';
// import './interceptor';
// import LoginLayout from './container/LoginLayoutRoute';
// import Login from './container/site/login';
import Home from './container/website/home';
import './css/style.scss';
import './css/themify-icons/themify-icons.css';
import ScrollToTop from './components/website/scrollToTop';
import Courses from './container/website/courses';
import FreeCourses from './container/website/freeCourses';
import Blog from './container/website/blog';
import BlogByCat from './container/website/blogByCat';
import About from './container/website/about';
import Contact from './container/website/contact';
import Faqs from './container/website/faqs';
import Login from './container/website/login';
import SignUp from './container/website/signup';
import CourseDetails from './container/website/courseDetails';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Testimonials from './container/website/testimonials';
import PageNotFound from './container/website/pagenotfound';
import BlogDetails from './container/website/blogDetails';
import Payment from './container/website/payment';
import { SnackbarProvider } from 'notistack';
import StudentsDashboard from './container/students/Dashboard';
import FreeResoucesItem from './container/students/FreeResoucesItem';
import StudentsFreeResouces from './container/students/FreeResouces';
import ViewStudentPayment from './container/students/ViewStudentPayment';
import ViewStudentOrder from './container/students/ViewStudentOrder';
import MyEnrolledDashboard from './container/students/MyEnrolledDashboard';
import ViewStudentModules from './container/students/ViewStudentModules';
import ViewStudentChapter from './container/students/ViewStudentChapter';
import ViewStudentVideo from './container/students/ViewStudentVideo';
import ThankYou from './container/website/ThankYou';
import ThankYouStudent from './container/students/ThankYou';
import ServerDown from './container/website/ServerDown';
import CMSPages from './container/website/CMSPages';
import CMSCareer from './container/website/CMSCareer';
// import HttpService from './interceptor';
import setUpAxios from "./interceptor";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './container/students/forgotPassword';
import ChangePassword from './container/students/ChangePassword';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

const history = createBrowserHistory();
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#1773bb',
      main: '#05376f',
      dark: '#1773bb'
    },
    secondary: deepOrange
  },
  props: {
    MuiTextField: {
    },
  },
  overrides: {
  },
});

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};

function App(props) {
  setUpAxios();

  ReactPixel.init('803833507032555', options);
  // ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');
  ReactGA.initialize('UA-179087616-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          maxSnack={3}>

          <Router history={history}>
            <ScrollToTop />
            <Switch>
              <LayoutRoute exact={true} path="/" component={Home} />
              <LayoutRoute exact={true} path="/courses" component={Courses} />
              <LayoutRoute exact={true} path="/courses/:coursesTitle" component={Courses} />
              <LayoutRoute exact={true} path="/view-course-details/:title/:id" component={CourseDetails} />
              <LayoutRoute exact={true} path="/faqs" component={Faqs} />
              <LayoutRoute exact={true} path="/free-resources" component={FreeCourses} />
              <LayoutRoute exact={true} path="/blog" component={Blog} />
              <LayoutRoute exact={true} path="/blog/:catgory" component={BlogByCat} />
              <LayoutRoute exact={true} path="/view-blog-details/:title/:id" component={BlogDetails} />
              <LayoutRoute exact={true} path="/about-us" component={About} />
              <LayoutRoute exact={true} path="/contact-us" component={Contact} />
              <LayoutRoute exact={true} path="/testimonials" component={Testimonials} />
              <LayoutRoute exact={true} path="/login" component={Login} />
              <LayoutRoute exact={true} path="/signup" component={SignUp} />
              <LayoutRoute exact={true} path="/payment" component={Payment} />
              <LayoutRoute exact={true} path="/thank-you" component={ThankYou} />
              <LayoutRoute exact={true} path="/cms/:pageTitle/:cmsId" component={CMSPages} />
              <LayoutRoute exact={true} path="/career/:pageTitle/:cmsId" TemplateType={"Career"} component={CMSCareer} />
              <NonLayoutRoute exact={true} path="/server-down" component={ServerDown} />
              <LayoutRoute exact={true} path="/forgot-password/" component={ForgotPassword} />
              <LayoutRoute exact={true} path="/student/verify/" component={Login} />
              <LayoutRoute exact={true} path="/student/change-password/" component={ChangePassword} />
              <LayoutStudentRoute exact={true} path="/students/dashboard/" component={StudentsDashboard} />
              <LayoutStudentRoute exact={true} path="/students/my-enrolled-courses/" component={MyEnrolledDashboard} />
              <LayoutStudentRoute exact={true} path="/students/order-history/" component={ViewStudentOrder} />
              <LayoutStudentRoute exact={true} path="/students/payment-history/" component={ViewStudentPayment} />
              <LayoutStudentRoute exact={true} path="/students/free-resouces/" component={StudentsFreeResouces} />
              <LayoutStudentRoute exact={true} path="/students/free-resouces/:title/:id" component={FreeResoucesItem} />
              <LayoutStudentRoute exact={true} path="/students/view-modules-by-course/:title/:id/:batch_id" component={ViewStudentModules} />
              <LayoutStudentRoute exact={true} path="/students/view-chapter-by-module/:title/:id/:module_title/:module_id" component={ViewStudentChapter} />
              <LayoutStudentRoute exact={true} path="/students/view-video-by-chapter/:title/:id/:module_title/:module_id" component={ViewStudentVideo} />
              <LayoutStudentRoute exact={true} path="/students/thank-you-purchase/" component={ThankYouStudent} />
              <LayoutRoute exact={true} path="**" component={PageNotFound} />

            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
