/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import { Button } from "@material-ui/core";
import { NavLink } from 'react-router-dom';

export default class CourseCFA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfa_menu: this.props.cfa_menu,
            hamburgerMenu: false,
            drodpdownState: false,
            mobileOnly: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
        this.resize();
    }

    resize() {
        const windwoWidth = window.innerWidth <= 990;
        this.setState({ hamburgerMenu: windwoWidth, mobileOnly: windwoWidth })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    handleClose() {
        this.setState({ hamburgerMenu: false })

    }
    handleOpen() {
        this.setState({ hamburgerMenu: true })
        this.props.handleOpen2(true)
    }

    render() {
        return (
            <React.Fragment>

                <li>
                    <div className='desktopVersion'>
                        {this.props.location === "footer" ? '' :
                            <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/about-us" > About Us</NavLink>
                        }
                    </div>
                    <div className='mobileVersion'>
                        {this.state.hamburgerMenu === false ?
                            <div className="" onClick={() => { this.handleOpen() }} >
                                <a>About Us  <span className='bigBtn'> - </span> </a></div>
                            :
                            <div className=" " onClick={() => { this.handleClose() }}> <a>About Us <span className='bigBtn'> + </span> </a> </div>
                        }
                    </div>
                    {this.state.hamburgerMenu === true ? ''
                        :
                        <ul>
                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''} to="/about-us" > About Us</NavLink></li>
                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''}  to="/faqs" > FAQs</NavLink> </li>
                            <li> <NavLink onClick={this.state.mobileOnly === true ? () => { this.handleOpen() } : ''}  to="/contact-us" > Contact Us</NavLink> </li>
                        </ul>
                    }
                </li>

            </React.Fragment>
        );
    }
}
