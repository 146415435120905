/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import girlUser from '../../images/girl.png';
import boyUser from '../../images/user.png';
import user_placeholder from '../../images/user_placeholder.png';

export default class TestimonialsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>

                <div className="col-md-12 col-sm-12 text-left elementGab">
                    <div className="testimonialsWrapper">
                        <div className="row justify-content-center align-items-center">

                            <div className="col-md-3 col-sm-12 testimonials-home">
                                <div className="image">
                                {this.props.cms_image == null ? <img src={user_placeholder} alt="" /> : <img src={process.env.react_app_base_url + "/" + this.props.cms_image} alt="" />}
                                </div>
                            </div>

                            <div className="col-md-9 col-sm-12">
                                <div className="textWrapper">
                                    <h4>{this.props.name}</h4>
                                    <p><strong>{this.props.course}</strong></p>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.desc }}></div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
