/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import LoginInner from '../../components/website/loginInner';
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            description: null,
            redirect: "/students/dashboard/",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log("fields", this.state.fields);
    }

    componentDidMount() {

        const query = new URLSearchParams(this.props.location.search);
        const email_token = query.get('email_token')
        console.log("email_token", email_token)
        if(email_token !== null){
           this.verifyEmail(email_token)
        }

        localStorage.removeItem("user_token")
        localStorage.removeItem("user_email")
        localStorage.removeItem("user_name")
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_user_type")
        

    }

    verifyEmail = (email_token) => {

        axios
            .get(process.env.react_app_base_url + "/api/v1/user/student/verify?email_token="+email_token)
            .then((res) => {
                console.log("res", res);
                const message = res.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });
                ReactPixel.fbq('track', 'CompleteRegistration');
                const resp = res.data;
                console.log("resp", resp)


            })
            .catch((error) => {
                console.log("res", error);
                if (error.response.status === 400) {
                    console.log("API error", error);
                    console.log("error.response", error.response.data.messages);

                    const message = error.response.data.messages;
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                } else {
                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                }
            });
    };

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Login"></SubHeader>
                <LoginInner redirect="/students/dashboard/"></LoginInner>
            </React.Fragment>
        );
    }
}

export default withSnackbar(Login);
