/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';
import { Loader } from '../../components/website/Loader';

class CMSPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: null,
            cmsId: null,
            loading: true,
            pageData: null,
        };
    }

    async componentDidMount() {

        const pageTitle = this.props.match.params.pageTitle;
        const cmsId = this.props.match.params.cmsId;
        this.setState({
            pageTitle: pageTitle,
            cmsId: cmsId,
        })
        this.getAllCMS(cmsId);
    }


    async getAllCMS(cmsId) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages/' + cmsId + "?status=0")
            .then(res => {
                console.log("CMS Pages res", res)
                this.setState({ pageData: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading == true ? <Loader></Loader> :
                    <React.Fragment>
                        <SubHeader title={this.state.pageData.title}></SubHeader>
                        <div className="section aboutus">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-sm-12 text-left CMSPages">
                                        {this.state.pageData.image == null ?
                                            '' :
                                            <div className="imagePlaceholder">
                                                <img src={this.state.pageData.image} />
                                            </div>
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: this.state.pageData.description }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default withSnackbar(CMSPages);
