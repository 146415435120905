/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import BlogItem from './elementItem';
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from 'react-router-dom';

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null
    };
  }


  async componentDidMount() {

    this.getAllCMS('Blog');

  }


  async getAllCMS(type) {

    this.setState({ loading: true })
    axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0&sortBy=created_on")
      .then(res => {
        console.log("getAllCMS res", res)

        const filteredDates = res.data.data.sort((a, b) => new Date(b.cms_publish_date) - new Date(a.cms_publish_date))
        console.log("filteredDates", filteredDates)
        this.setState({ tableData: filteredDates, loading: false })

      })
      .catch(error => {

        console.log("error.response.status", error)

      });

  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="blog">
        <h2 className="">
          <NavLink to="/blog"> Our Blog</NavLink>
        </h2>
        {this.state.tableData === null ? '' : this.state.tableData.slice(0, 8).map(item => {
          return (
            <p> <NavLink to={`/view-blog-details/${item.cms_title.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"}> {item.cms_title}</NavLink> </p>

          )
        })
        }
      </div>
    );
  }
}
