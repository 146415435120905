/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { withSnackbar } from 'notistack';
import axios from 'axios';
import LinkItem from '../../components/LinkItem';
import { Loader } from "../../components/website/Loader";
import moment from "moment";


class MyEnrolledDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            cms_description: null,
            cms_type: [],
            cms_category: [],
            type: null,
            cms_details: null,
            api_reps: false,
            action: null,
            tableMyDataCourses: null,
            allCourse: null,
            tableData: null,
            user_token: localStorage.getItem('user_token'),
            user_email: localStorage.getItem('user_email'),
            organization_id: localStorage.getItem('organization_id'),
            user_name: localStorage.getItem('user_name'),
            user_id: localStorage.getItem('user_id')
        };
    }

    async componentDidMount() {

        this.getAllCMS('Resources');
        this.getAllCourses();
        this.getAllBatch();
        this.getCourses()

    }

    async getAllCourses() {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/order/getEnrolledByUserId/' + this.state.user_id, {
            headers: {
                "auth-token": `${this.state.user_token}`,
            }
        })
            .then(res => {
                console.log("getMyCourses res", res.data.data)
                this.setState({ tableMyDataCourses: res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)), loading: false })
                // console.log("course_details", course_details)
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type + "?status=0")
            .then(res => {
                console.log("Resources res", res)

                this.setState({ tableData: res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)), loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });
    }


    async getAllBatch(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/centerbatch?status=undefined',
            {
                headers: {
                    "auth-token": `${this.state.user_token}`,
                }
            })
            .then(res => {
                console.log("Courses res", res)
                const dataNew = res.data.data;
                console.log("dataNew", dataNew.length)
                this.setState({ centers: res.data.data.reverse(), loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    async getCourses(id) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0',
            {
                headers: {
                    "auth-token": `${this.state.user_token}`,
                }
            })
            .then(res => {
                console.log("Courses res", res)
                const dataNew = res.data.data;
                console.log("dataNew", dataNew.length)
                this.setState({ allCourse: res.data.data, loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="student-dashboard">
                    <div className="container">
                        <div className="row text-left">
                            <div className="col-md-12">
                                <div class="card mb-3">
                                    <div class="card-header bg-secondary">
                                        <h3>My Enrolled Courses </h3>
                                    </div>
                                    <div class="card-body" style={{ padding: "0px" }}>
                                        <div className="admin-list">
                                            <table className="table table-bordered">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th> Course Name / Batch Name </th>
                                                        <th> Action</th>
                                                    </tr>
                                                </thead>
                                                {this.state.tableMyDataCourses === null ? <Loader></Loader> :
                                                    this.state.tableMyDataCourses.map((item) => {
                                                        const batchDetails = this.state.centers?.find((i) => item?.batch_id === i?._id)
                                                        const CourseDetails = this.state.allCourse?.find((i) => item?.course_id === i?._id)

                                                        const today = moment().format("MM/DD/YYYY")
                                                        const accessEndDate = moment(item?.access_end_date).format("MM/DD/YYYY")
                                                        const dateAfter = moment(accessEndDate).isAfter(today)

                                                        const accessBatchEndDate = moment(batchDetails?.access_end_date).format("MM/DD/YYYY")
                                                        const dateDetailsAfter = moment(accessBatchEndDate).isAfter(today)

                                                        let isActiveAccess = "inactive"
                                                        if (CourseDetails?.parent_course === 'Elearnings') {
                                                            console.log("CourseDetails item", CourseDetails?.parent_course, item?.access_end_date, dateAfter)
                                                            if (dateAfter) {
                                                                isActiveAccess = "active"
                                                            }
                                                        }
                                                        if (CourseDetails?.parent_course !== 'Elearnings') {
                                                            if (dateAfter || dateDetailsAfter) {
                                                                isActiveAccess = "active"
                                                            } else {
                                                                isActiveAccess = "inactive"
                                                            }
                                                        }

                                                        return (
                                                            <>
                                                                {isActiveAccess === "active" ?
                                                                    <tr>
                                                                        <td data-access_end_date={item?.access_end_date && moment(item?.access_end_date).format("DD/MM/YYYY")}
                                                                            data-batch={batchDetails?.access_end_date && moment(batchDetails?.access_end_date).format("DD/MM/YYYY")}
                                                                            data-batch-status={batchDetails?.status === "1" ? 'Inactive' : 'active'}
                                                                        >
                                                                            <strong>{item.course_name}</strong> <br></br>
                                                                            {batchDetails?.name} <br></br>
                                                                            {/* {item?.access_end_date && moment(item?.access_end_date).format("DD/MM/YYYY")} <br></br> */}
                                                                            {/* {batchDetails?.access_end_date && moment(batchDetails?.access_end_date).format("DD/MM/YYYY")} <br></br> */}
                                                                        </td>
                                                                        <td style={{ width: "150px" }}> <LinkItem className="view-details" variant="primary"
                                                                            to={`/students/view-modules-by-course/${item.course_name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item.course_id.replace(/\s+/g, '-')}` + "/" + item.batch_id + "/"}
                                                                            title={'View Details'}></LinkItem></td>
                                                                    </tr>
                                                                    : ''}
                                                            </>
                                                        )
                                                    })
                                                }
                                            </table>
                                            {this.state.tableMyDataCourses === null ? '' : this.state.tableMyDataCourses.length !== 0 ? '' : 'You have not buy any course.'}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(MyEnrolledDashboard);
