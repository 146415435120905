/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import LinkItem from '../../components/LinkItem';
import axios from 'axios';
import DefaultImage from '../../images/default2.jpg';
import { Loader } from '../../components/website/Loader';

export default class Courses extends Component {
    constructor(props) {
        console.log("props", props?.match?.params?.coursesTitle)
        super(props);
        this.state = {
            tableData: null,
            coursesTitle: props?.match?.params?.coursesTitle
        };
    }

    async componentDidMount() {
        this.getAllCMS();
    }

    async componentWillReceiveProps(nextProps) {
        console.log("coursesTitle", nextProps, nextProps?.match?.params?.coursesTitle)
        if (nextProps?.match?.params?.coursesTitle !== this.state.coursesTitle) {
            this.setState({ coursesTitle: nextProps?.match?.params?.coursesTitle });
        }

        this.getAllCMS();
    }

    // async getAllCMS() {
    //     this.setState({ loading: true })
    //     axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0')
    //         .then(res => {
    //             console.log("Courses res", res)
    //             this.setState({ tableData: res.data.data, loading: false })
    //         })
    //         .catch(error => {
    //             console.log("error.response.status", error)
    //         });
    // }

    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0')
            .then(res => {
                let data = []
                if (this.state.coursesTitle === "Elearnings") {
                    data = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(item => item.parent_course === "Elearnings");
                } else if (this.state.coursesTitle === "certificate-courses") {
                    console.log("Courses res", res)
                    data = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(item => item.parent_course === "None");
                    console.log("cfa_menu", data)
                } else {

                    data = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(item => item.parent_course === "CFA");
                    console.log("other_menu", data)
                }

                this.setState({
                    tableData: data, loading: false
                    // cfa_menu: cfa_menu, other_menu: other_menu, loading: false
                })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title={
                    this.state.coursesTitle === "Elearnings" ? 'Self-paced E-learnings' : this.state.coursesTitle === "certificate-courses" ? 'Certificate Courses' : 'FRM'}>
                </SubHeader>
                <div className="container">
                    <div className="coursesComp">
                        <div className="row">
                            {this.state.tableData === null ?
                                <Loader></Loader>
                                : this.state.tableData.map(item => {
                                    const link = `/view-course-details/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}` + "/"
                                    return (
                                        <React.Fragment>
                                            <div className="col-md-4 mb-3 cursorPointer" onClick={link}>
                                                <div className='inner'>
                                                    <div className="image" style={{ background: `url(${process.env.react_app_base_url + "/" + item.image})` }}>
                                                    </div>
                                                    <h3>
                                                        <LinkItem
                                                            title={item.name}
                                                            to={link}
                                                        >
                                                        </LinkItem>
                                                    </h3>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
