/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import axios from 'axios';
import CourseCFA from './CourseCFA';
import CertificateCourse from './CertificateCourse';
// import CourseCFA from './CourseCFA';
import CourseLearnings from './CourseLearnings';
export default class TopCourseMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfa_menu: null,
            other_menu: null,
        };
    }

    async componentDidMount() {

        this.getAllCMS();

    }
    handleOpen2 (){
        this.props.handleOpen2(true)
    }


    async getAllCMS(type) {
        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0')
            .then(res => {
                console.log("Courses res", res)
                const cfa_menu = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(item => item.parent_course === "CFA");
                console.log("cfa_menu", cfa_menu)
                const other_menu = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(item => item.parent_course === "None");
                console.log("other_menu", other_menu)
                const learningsr_menu = res.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(item => item.parent_course === "Elearnings");
                
                this.setState({ cfa_menu: cfa_menu, other_menu: other_menu,learningsr_menu:learningsr_menu,  loading: false })
            })
            .catch(error => {
                console.log("error.response.status", error)
            });

    }

    render() {
        return (
            <React.Fragment>
                {this.state.other_menu == null ? '': <CertificateCourse  handleOpen2={() => this.handleOpen2()} other_menu={this.state.other_menu}></CertificateCourse> }
                {/* {JSON.stringify(this.state.learningsr_menu)} */}
                {this.state.learningsr_menu ? <CourseLearnings  handleOpen2={() => this.handleOpen2()} learningsr_menu={this.state.learningsr_menu}></CourseLearnings> : '' }    
                {this.state.cfa_menu == null ? '': <CourseCFA  handleOpen2={() => this.handleOpen2()} cfa_menu={this.state.cfa_menu}></CourseCFA> }            
            </React.Fragment>
        );
    }
}
