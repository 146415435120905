/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import LinkItem from '../LinkItem';
import { Button } from "@material-ui/core";
import axios from 'axios';
import CourseMenuItem from './TopCourseMenuItem';

export default class TopMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfa_menu: null,
            other_menu: null,
        };
    }

    async componentDidMount() {



    }


    async getAllCMS(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cources?status=0')
            .then(res => {
                console.log("Courses res", res)

                const cfa_menu = res.data.data.filter(item => item.parent_course === "CFA");
                console.log("cfa_menu", cfa_menu)

                const other_menu = res.data.data.filter(item => item.parent_course !== "CFA");
                console.log("other_menu", other_menu)


                this.setState({ cfa_menu: cfa_menu, other_menu: other_menu, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)


            });

    }

    render() {
        return (
            <React.Fragment>
                <ul>
                    <CourseMenuItem></CourseMenuItem>
                    <li><LinkItem to={`/career/career-counselling-for-BFSI/6082dfa16152290d084f7492/`} title={'Career Counselling - BFSI'}></LinkItem> </li>
                    <li><LinkItem to="/free-resources" title="Free Resources"></LinkItem> </li>
                    <li><LinkItem to="/blog" title="Blog"></LinkItem> </li>
                    <li><LinkItem to="/testimonials" title="Testimonials"></LinkItem> </li>
                    <li><LinkItem to="/about-us" title="About Us"></LinkItem>
                        <ul>
                            
                            <li><LinkItem to="/faqs" title="FAQs"></LinkItem> </li>
                            <li><LinkItem to="/contact-us" title="Contact Us"></LinkItem> </li>
                        </ul>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}
