/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import USP from '../../components/website/usp';
import AboutImage from '../../images/aboutUs.jpg';
import axios from 'axios';
import FreeResources from '../../components/website/freeResources';
import Placeholder from '../../images/placeholder.jpg'
import { Button } from '@material-ui/core';
import { Loader } from '../../components/website/Loader';

export default class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: null,
            tableData: null
        };

    }

    async componentDidMount() {
        this.getAllPages();
        this.getAllCMS2('Instructor');
    }

    getAllPages() {
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages')
            .then(res => {
                console.log("getAllPages res", res.data.data)
                const meet_trainer = res.data.data.filter(item => item._id == "5f7746278e396339ca9b03d2");
                console.log("meet_trainer", meet_trainer[0])
                this.setState({ pages: meet_trainer[0] })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }





    async getAllCMS2(type) {

        this.setState({ loading: true })
        axios.get(process.env.react_app_base_url + '/api/v1/cms/cms/bytype/' + type)
            .then(res => {
                console.log("Testimonials res", res)

                this.setState({ tableData: res.data.data, loading: false })

            })
            .catch(error => {

                console.log("error.response.status", error)

            });

    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="About FinQuest Institute"></SubHeader>
                <div className="section aboutus">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-sm-12 text-left">
                                {this.state.pages === null ?  <Loader></Loader> : <div dangerouslySetInnerHTML={{ __html: this.state.pages?.description }}></div>}
                            </div>
                        </div>
                    </div>
                </div>

                <hr></hr>
                <div className="section aboutus">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-sm-12 text-left aboutTrainers" id='aboutTrainers'>
                                <h3>About the Trainers </h3>
                                <div className="row">
                                    {this.state.tableData === null ?  <Loader></Loader> : this.state.tableData?.map(item => {
                                        return (
                                            <React.Fragment>
                                                <div className="col-md-6 col-sm-6 text-left">
                                                    <div className='inner'>
                                                        <p>{item.cms_image == 'undefined' || item.cms_image == null ? <img src={Placeholder} alt="" /> : <img src={process.env.react_app_base_url + "/" + item.cms_image} alt="" />}</p>
                                                        <h3> <div dangerouslySetInnerHTML={{ __html: item.cms_title }}></div></h3>
                                                        {item.cms_tags &&
                                                            <p>
                                                                <a style={{ marginTop: "-20px", display: 'block', float: 'left' }} rel="noopener noreferrer" target="_blank" href={item?.cms_tags} fontIcon="ti-linkedin" title="">
                                                                    <span className={'fontIcon  ti-linkedin'}></span>
                                                                    <div className='clearfix'></div>
                                                                </a>
                                                                
                                                            </p>
                                                        }
<div className='clearfix'></div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.cms_description }}></div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }


                                </div>


                            </div>
                        </div>
                    </div>
                </div>



                {/* <div className="section mission-vision">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-sm-12 text-left">
                                <div className="innerpadding">
                                    <h3>Following are Ameya's academic qualifications:</h3>
                                    <ul>
                                        <li>Chartered Financial Analyst (CFA), CFA Institute USA</li>
                                        <li>Certificate in Quantitative Finance (CQF), CQF Institute, UK</li>
                                        <li>Certificate in Machine Learning for Finance, MLI Institute, UK</li>
                                        <li>Masters in Business Administration (MBA) with specialization in Finance</li>
                                        <li>Bachelors of Engineering</li>
                                    </ul>
                                    <h5>Ameya is an active blogger. He regularly publishes articles on various topics around quantitative finance, Python programming, and analytics.</h5>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 text-left nopadding">
                                <img src={AboutImage} alt="" />
                            </div>


                        </div>
                    </div>
                </div> */}

                <div className='blog_resource'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2>Resources</h2>
                            </div>
                            <div className='col-md-6'><FreeResources></FreeResources></div>
                            <div className='col-md-6'>
                                <div className="blog freeresources">
                                    <h3>Why Choose Us</h3>
                                    <USP></USP>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




            </React.Fragment>
        );
    }
}
