/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
export default class SubHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="subHeader">
                    <div className="container">
                        <h1>{this.props.title}</h1>
                        {this.props.para && <p>{this.props.para}</p>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
