/* eslint-disable require-jsdoc */
import React, { useState, useEffect } from "react";
import { withSnackbar } from 'notistack';
import BlogItem from '../../components/website/elementItem';
import StudentHeader from '../../components/website/studentHeader';
import LinkItem from '../../components/LinkItem';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubHeader from '../../components/website/subHeader';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import axios from 'axios';

import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/website/Loader";
import { Button, Card } from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const ViewStudentChapter = (props) => {
    const [value, setValue] = useState(0);
    const [files, setFiles] = useState([]);
    const [fields, setFields] = useState({});
    const [cmsDescription, setCmsDescription] = useState(null);
    const [cmsType, setCmsType] = useState([]);
    const [cmsCategory, setCmsCategory] = useState([]);
    const [type, setType] = useState(null);
    const [cmsDetails, setCmsDetails] = useState(null);
    const [apiReps, setApiReps] = useState(false);
    const [action, setAction] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [tableModuleData, setTableModuleData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [moduleTitle, setModuleTitle] = useState(null);
    const [moduleId, setModuleId] = useState(null);
    const token = localStorage.getItem('admin_token');
    const organizationId = localStorage.getItem('organization_id');
    const adminName = localStorage.getItem('admin_name');
    const currentCourseBatchId = localStorage.getItem("currentCourseBatchId") || null;

    useEffect(() => {
        const { title, id, module_title, module_id } = props.match.params;
        console.log("id, title, module_title, module_id", id, title, module_title, module_id);
        getAllCMS(id);
        getAllModuleByCourse(module_id);
        setModuleTitle(module_title);
        setModuleId(module_id);
    }, [props.match.params]);

    const getAllCMS = async (id) => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.react_app_base_url}/api/v1/chapters/chapterBymodules/${id}?status=0&batch_id=${currentCourseBatchId}`);
            console.log("Resources chapterBymodules", res);
            setTableData(res.data.data);
        } catch (error) {
            console.log("error.response.status", error);
        } finally {
            setLoading(false);
        }
    };

    const getAllModuleByCourse = async (module_id) => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.react_app_base_url}/api/v1/modules/moduleByCourse/${module_id}?status=0&batch_id=${currentCourseBatchId}`);
            console.log("Resources moduleByCourse", res);
            setTableModuleData(res.data.data);
        } catch (error) {
            console.log("error.response.status", error);
        } finally {
            setLoading(false);
        }
    };




    const batch_id = localStorage.getItem('currentCourseBatchId');
    const [courseModuleId, setCourseModuleId] = useState("")
    const [allVideos, setAllVideos] = useState([])
    const [allVideosLoading, setAllVideosLoading] = useState(false)
    const getAllVideoByModule = async (id) => {
        setAllVideos([])
        setAllVideosLoading(false);
        try {
            const res = await axios.get(`${process.env.react_app_base_url}/api/v1/videos/videosByChapter/${id}?status=0&batch_id=${currentCourseBatchId}`);
            console.log("Resources getAllVideoByModule", res);
            setAllVideos(res.data.data);
            setAllVideosLoading(true)
        } catch (error) {
            console.log("error.response.status", error);
            setAllVideosLoading(false);
        }
    };

    const getVideoByCourse = (id) => {
        console.log("----------------", id)
        setCourseModuleId(id)
        getAllVideoByModule(id)
    }

    return (
        <React.Fragment>
            <StudentHeader></StudentHeader>
            <div className="container">
                <p className="backtoBtn">
                    <LinkItem to={`/students/view-modules-by-course/${moduleTitle}/${moduleId}/${batch_id}`} title={'Back to Module'}></LinkItem>
                </p>
            </div>
            <div className="student-dashboard">
                <div className="container">
                    <div className="row text-left">
                        <div className="col-md-12" id="mainVideo2">
                            {/* {tableModuleData && tableModuleData.map((item, index) => (
                                <Paper square key={index}>
                                    <TabPanel value={value} index={0}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div>
                                                    <Typography className={'text-underline'}>
                                                        {index + 1}. {item.name} {item.cms_category === "null" ? '' : item.cms_category}
                                                    </Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                <div className="clearfix"></div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </TabPanel>
                                </Paper>
                            ))} */}

                            {tableData === null ? <Loader /> : tableData.length === 0 ? 'Chapter yet not uploaded for this module.' : ' '}

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="list">
                                        {tableData && tableData.map((item, index) => {
                                            return (
                                                <div className="item">
                                                    <div className={`${courseModuleId === item?._id ? 'active' : ''} item_list`} onClick={() => getVideoByCourse(item?._id)}>
                                                        <div> {item?.name}</div>
                                                        <div> {courseModuleId === item?._id ? <span className="ti-arrow-up"></span> : <span className="ti-arrow-down"></span>}</div>
                                                    </div>
                                                    {courseModuleId === item?._id &&
                                                        <div className="item_des">
                                                            <p><strong>Video List</strong></p>
                                                            {!allVideosLoading && 'Loading ...'}
                                                            <div className="row">
                                                                {allVideos && allVideos?.map((video) => {
                                                                    return (
                                                                        <div className="col-md-4">
                                                                            <div className="inner">
                                                                                <p className="mb-2"><strong>{video.name}</strong></p>
                                                                                <div className="clearfix"></div>
                                                                                <p className="">
                                                                                    <LinkItem className="view-details"
                                                                                        variant="primary"
                                                                                        to={`/students/view-video-by-chapter/${video.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${video._id.replace(/\s+/g, '-')}/${video.module_name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${video.module_id.replace(/\s+/g, '-')}/`}
                                                                                        title={'View Video & Details'}
                                                                                    ></LinkItem>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                })}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            {/* <Paper square>
                                <TabPanel value={value} index={0}>
                                    {tableData && tableData.map((item, index) => (
                                        <Accordion key={index}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={'text-underline'}>
                                                    {index + 1}. {item.name} {item.cms_category === "null" ? '' : item.cms_category}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                <div className="clearfix"></div>
                                                <p className="float-left">
                                                    <LinkItem className="view-details"
                                                        variant="primary"
                                                        to={`/students/view-video-by-chapter/${item.name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item._id.replace(/\s+/g, '-')}/${item.module_name.replace(/\s+/g, '-').toLowerCase().replace(",", "").replace("(", "").replace(")", "").replace("/", "-").replace("!", "").replace("@", "-").replace("#", "-").replace("$", "-").replace("%", "-").replace("^", "-").replace("&", "-").replace("*", "-").replace("_", "-").replace(".", "-").replace(";", "-").replace("'", "-").replace("'", "-").replace(":", "-").replace("{", "").replace("}", "-").replace("[", "-").replace("]", "-").replace("=", "-").replace("+", "-").replace("|", "-").replace(/"/g, "'").replace(/\\\//g, "")}/${item.module_id.replace(/\s+/g, '-')}/`}
                                                        title={'View Details'}
                                                    ></LinkItem>
                                                </p>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </TabPanel>
                            </Paper> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withSnackbar(ViewStudentChapter);
