/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ContactForm from '../../components/website/contactForm';

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Contact Us"></SubHeader>

                <div className="section aboutus">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-sm-12 text-left">

                                <p>We would love to provide you with more information and answer any questions that you might have. We look forward to hearing from you.</p>
                                <p>&nbsp;</p>

                                <div className="row justify-content-center">
                                    <div className="col-md-5 col-sm-12 text-left">
                                        <div className='inner'>
                                            <h3>Get In Touch</h3>
                                            <p>Drop us a line, someone from our team will get in touch with you shortly.</p>
                                            <ContactForm></ContactForm>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-center">
                                        <div className="contact_info">
                                            <div className="text-left">
                                                <h3>Contact Info</h3>
                                                <p><span className="ti-email iconSpan"></span>  Email : <a href="mailto:info@finquestinstitute.com" title="info@finquestinstitute.com">info@finquestinstitute.com</a> </p>
                                                <p><span className="ti-headphone iconSpan"></span>  Phone : +91-8591017994 </p>
                                                <p><span className="ti-location-pin iconSpan"></span>  Address : Indravadan, Shivaji Park, Mumbai: 400016, India </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <iframe style={{ border: 'none' }} height={'450px'} width={'100%'} title="Contact map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.8625496403083!2d72.83950471490039!3d19.02577748711671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ced044683b0d%3A0x488167caa03c8f8c!2sIndravadan%20Society%2C%20Kasaravadi%2C%20Dadar%2C%20Mumbai%2C%20Maharashtra%20400028!5e0!3m2!1sen!2sin!4v1599370764966!5m2!1sen!2sin"></iframe>
            </React.Fragment>
        );
    }
}
