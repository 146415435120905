/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import { withRouter } from "react-router";

class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            organization_id: '5f048db166e1c93c780e7e7c',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log('fields', this.state.fields);

    }

    Subscribe = () => {

        const formData = new FormData();
        formData.append('organization_id', this.state.organization_id);
        formData.append('email', this.state.fields.email);
        formData.append('status', '1');
        formData.append('created_by', 'Website');

        console.log(JSON.stringify(formData));

        axios.post(process.env.react_app_base_url + '/api/v1/frontend/subscribe', formData)
            .then(res => {
                console.log("res", res)
                const message = res.data.messages;

                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 7000,
                });

                this.setState({ fields: { "email": '' } })
                this.props.history.push("/thank-you/")

            })
            .catch(error => {

                console.log("error.response.status", error)

                const message = error.response.data.message;
                this.props.enqueueSnackbar(message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });


            });

    }

    render() {
        return (
            <React.Fragment>
                <div className="stayuptpdate">
                    <div className="container">
                        <div className="row text-left">
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                <h3>Stay up to date</h3>
                                <p>Your email will be used to send you our new blog posts and updates. You can unsubscribe at any time using the link in our emails.</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <div className="stayuptpdateform">
                                    <p><TextField name="email" size="small" value={this.state.fields.email} onChange={this.handleChange} id="outlined-basic" label="Email Id" variant="outlined" /></p>
                                    <p><Button onClick={this.Subscribe} variant="contained" color="primary"> Subscribe </Button></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// export default withSnackbar(Newsletter);
export default withRouter(withSnackbar(Newsletter));