/* eslint-disable require-jsdoc */
import React, { Component } from 'react';

import CoursesComp from '../../components/website/courses';

import AboutHome from '../../components/website/aboutHome';
import Blog from '../../components/website/blog';
import FreeResources from '../../components/website/freeResources';
import Testimonials from '../../components/website/testimonials';
import Instructors from '../../components/website/OurInstructors';
export default class home extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                <AboutHome></AboutHome>
                <CoursesComp></CoursesComp>
                <Testimonials></Testimonials>
                <Instructors></Instructors>
                <div className='blog_resource'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2>Resources</h2>
                            </div>
                            <div className='col-md-6 mb-3'><FreeResources></FreeResources></div>
                            <div className='col-md-6 mb-3'><Blog></Blog></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
