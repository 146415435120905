/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import axios from "axios";
import Logo from "../../images/logo.jpg";
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            description: null,
            redirect: props.redirect,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleLangChange = this.handleLangChange.bind(this);
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log("fields", this.state.fields);
    }

    componentDidMount() {
    }

    login = () => {
        const formData = new FormData();
        formData.append("name", this.state.fields.name);
        formData.append("email", this.state.fields.email);
        formData.append("password", this.state.fields.password);
        formData.append("mobile", this.state.fields.mobile);
        formData.append("status", "1");
        formData.append("organization_name", "Finquest Institute");
        formData.append("board", 'Finquest Institute');
        formData.append("standard", "Online");
        formData.append("invited_by", null);
        formData.append("division", "Online");

        console.log("Login", JSON.stringify(formData));

        axios
            .post(process.env.react_app_base_url + "/api/v1/user/register/student", formData)
            .then((res) => {
                const resp = res.data;
                console.log("resp resp", resp);
                const message = res.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });

                if (this.state.redirect == undefined) {

                    console.log("no redirect")
                    this.handleLangChange(resp.user)

                } else {



                    this.props.history.push({
                        pathname: "/login/",
                    });

                }

            })
            .catch((error) => {
                console.log("error.response.status", error)
                if (error.response.status === 400) {

                    console.log("API error", error);
                    console.log("error.response.data.messages", error.response.data.messages);

                    const message = error.response.data.messages;
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });



                } else {

                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            });
    };

    handleLangChange(user_id) {
        this.props.onSignUp(user_id);
    }

    render() {
        return (
            <React.Fragment>
                <div className="adminLogin">
                    <div className="loginForm2">
                        <p>Welcome User</p>
                        <p>Sign Up to FinQuest Institute Students Panel</p>
                        <div className="mb-3">
                            <TextField
                                name="name"
                                size="medium"
                                value={this.state.fields.name}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                            />
                        </div>

                        <div className="mb-3">
                            <TextField
                                name="email"
                                size="medium"
                                value={this.state.fields.email}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Email  Id"
                                variant="outlined"
                            />
                        </div>


                        <div className="mb-3">
                            <TextField
                                name="password"
                                size="medium"
                                type="password"
                                value={this.state.fields.password}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                            />
                        </div>

                        <div className="mb-3">
                            <TextField
                                name="mobile"
                                size="medium"
                                type="number"
                                value={this.state.fields.mobile}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Mobile"
                                variant="outlined"
                            />
                        </div>

                        <div className="mb-3">
                            <Button
                                variant="contained"
                                size="large"
                                className="display-block"
                                color="primary"
                                onClick={this.login}
                            > Submit </Button>
                        </div>



                        <div className="hide-on-popup">
                            <h5 className="divider"><span>Have Finquest Institute Account?</span></h5>
                            <NavLink className="smalltext" to="/login/"><Button variant="outlined" color="primary">Login</Button></NavLink>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withSnackbar(SignUp));

