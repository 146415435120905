/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import LoginInner from '../../components/website/loginInner';
import axios from "axios";

import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { NavLink } from 'react-router-dom';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            fields: {},
            description: null,
            redirect: "/students/dashboard/",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields,
        });

        console.log("fields", this.state.fields);
    }

    componentDidMount() {

        localStorage.removeItem("user_token")
        localStorage.removeItem("user_email")
        localStorage.removeItem("user_name")
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_user_type")

    }

    login = () => {

        axios
            .get(process.env.react_app_base_url + "/api/v1/user/student/resetpassword?email="+this.state.fields.email)
            .then((res) => {
                console.log("res", res);
                const message = res.data.messages;
                this.props.enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: 3000,
                });               
            })
            .catch((error) => {
                console.log("res", error);
                if (error.response.status === 400) {
                    console.log("API error", error);
                    console.log("error.response", error.response.data.messages);

                    const message = error.response.data.messages;
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                } else {
                    const message = "something went wrong, please try again";
                    this.props.enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: 3000,
                    });

                }
            });
    };

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Forgot Password"></SubHeader>
                <div className="adminLogin">
                    <div className="loginForm2">
                        <p>Forgot Your Password?</p>
                        <p>To reset your password, please enter your finquestinstitute.com registered Email Id.
                        finquestinstitute.com will send the password reset instructions to the email address for this account.
</p>
                        <div className="mb-3">
                            <TextField
                                name="email"
                                size="medium"
                                value={this.state.fields.email}
                                onChange={this.handleChange}
                                id="outlined-basic"
                                label="Email  Id"
                                variant="outlined"
                            />
                        </div>


                        <div className="mb-3">
                            <Button
                                variant="contained"
                                size="large"
                                className="display-block"
                                color="primary"
                                onClick={this.login}
                            >
                                {" "}
                Submit{" "}
                            </Button>
                        </div>
                        <p>
                            <NavLink className="smalltext" to="/login/">Login</NavLink></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(ForgotPassword);
