import React from "react"
import StudentHeader from "../../components/website/studentHeader"
import MyEnrolledDashboardInner from "./MyEnrolledDashboardInner"
const MyEnrolledDashboard = () => {
    return (
        <>
            <StudentHeader></StudentHeader>
            <MyEnrolledDashboardInner></MyEnrolledDashboardInner>
        </>
    )
}

export default MyEnrolledDashboard