/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import MeetYourTrainer from '../../images/meettrainer_photo.jpg'
import SocialIcons from './social';
import axios from 'axios';

export default class MeetTrainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: null

        };
    }

    async componentDidMount() {
        this.getAllPages();
    }

    getAllPages() {
        axios.get(process.env.react_app_base_url + '/api/v1/cms/pages')
            .then(res => {
                console.log("getAllPages res", res.data.data)
                const meet_trainer = res.data.data.filter(item => item._id == "5f7743b18e396339ca9b03d1");
                console.log("meet_trainer", meet_trainer[0])
                this.setState({ pages: meet_trainer[0] })

            })
            .catch(error => {
                console.log("error.response.status", error)
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="meetTrainer">
                    {this.state.pages !== null ?
                        <div className="container">
                            <div className="testimonials">
                                <div className="row">

                                {/* {JSON.stringify(this.state.pages)} */}
                                    <div className="col-md-8 col-sm-12">
                                        <div className="textWrapper text-left">

                                            <h2 className="text-left">{this.state.pages.title} </h2>
                                            
                                            <div dangerouslySetInnerHTML={{ __html: this.state.pages.description }}></div>

                                            {/* <p>Ameya is the Founder and Lead Trainer at FinQuest Institute, is a CFA charter holder, CQF certified expert and an MBA (Finance).
                                            He has a professional experience spanning 12+ years across the domains of banking, financial services and technology.
                                            Ameya will leverage his professional experience and relate it with the theoretical concepts that will be taught in his sessions.
                                            This will help candidates to connect the theoretical concepts studied in class with their practical business applications.
                                            Further, Ameya has a teaching experience spanning 5+ years at the post-graduate level in his capacity as a Visiting Faculty for Finance at prestigious management and economics Institutes. During his tenure in the industry,
                                            Ameya has delivered training programs for: a central bank officials in a South East Asian country on bond markets which helped that central bank to enhance their markets framework around fixed income products;
                                            and he has also developed pricing and risk measurement models for a quantitative analytics desk of a private bank and the same is being used by this desk for independent pricing and risk measurement of their treasury portfolio.</p>
                                            <p>&nbsp;</p>
                                            <h5>Ameya P. Abhyankar,</h5>
                                            <p>Founder & Lead Trainer, FinQuest Institute</p> */}
                                            <SocialIcons></SocialIcons>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-12">
                                        <div className="image">
                                            <img src={process.env.react_app_base_url +"/"+this.state.pages.image} alt="" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        : ''}
                </div>
            </React.Fragment>
        );
    }
}
