/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import SubHeader from '../../components/website/subHeader';
import { withSnackbar } from "notistack";
import LoginInner from '../../components/website/loginInner';
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        ReactPixel.fbq('track', 'Lead');
    }

    render() {
        return (
            <React.Fragment>
                <SubHeader title="Thank you"></SubHeader>
                <div className="thanks-message">
                    <h2>Thank you for getting in touch!</h2>
                    <p>We appreciate you contacting us. One of our colleagues will get back in touch with you soon!</p>
                    <p>Have a great day!</p>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(Login);
